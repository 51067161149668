import { httpCall } from './httpAction'

export const fetchAppLaunch = async () => {
    // console.log("fetchAppLaunch");

    try {
        const [success, error, statusCode, data] = await httpCall('get', process.env.NEXT_PUBLIC_API_HOST + '/api/v2/appLaunch', null, null);

        if (success == true) {
            // console.log(data);

            return ([true, null, data]);
        } else {
            return ([false, error, null]);
        }
    } catch (error) {
        console.log("error", error);
        return ([false, error, null]);
    }
}